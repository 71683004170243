
import Vue from "vue"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useCopilotStore } from "@/stores/copilot"
import { User } from "@evercam/shared/types"
import CopilotChat from "@evercam/shared/components/copilot/CopilotChat.vue"
import interact from "interactjs"
import { useNuxtApp } from "#app"

export default Vue.extend({
  name: "TheCopilotCallout",
  components: { CopilotChat },
  data() {
    return {
      isMounted: false,
      width: 600,
      height: 750,
      x: 0,
      y: 0,
      translateY: 0,
      preCollapseRect: {},
      isResizing: false,
      isDragging: false,
      isInteractive: false,
      currentPageId: "",
    }
  },
  computed: {
    ...mapStores(
      useAccountStore,
      useProjectStore,
      useCameraStore,
      useCopilotStore
    ),
    user(): User {
      return this.accountStore.userObject
    },
    token(): string {
      return this.accountStore.token
    },
    containerStyles(): Record<string, unknown> {
      return {
        transform: `translate(${this.x}px, ${this.y + this.translateY}px)`,
        width: `${this.width}px`,
        height: `${this.height}px`,
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.currentPageId = useNuxtApp()?.nuxt2Context?.route?.meta[0]?.pageId
      },
    },
    "copilotStore.isCollapsed"() {
      if (!this.copilotStore.isActive) {
        return
      }

      this.onCollapsedChange()
    },
    "copilotStore.isActive"(isActive) {
      if (isActive && this.copilotStore.isFloating) {
        this.initializeInteract()
      } else {
        this.destroyInteract()
      }
    },
  },
  mounted() {
    this.isMounted = true
  },
  beforeDestroy() {
    this.destroyInteract()
  },
  methods: {
    onCollapsedChange() {
      if (!this.isInteractive) {
        this.initializeInteract()
        this.$setTimeout(this.onCollapsedChange, 100)

        return
      }
      const copilotElement = this.$refs.copilotContainer as HTMLElement
      const boundingRect = copilotElement.getBoundingClientRect()
      if (this.copilotStore.isCollapsed) {
        this.preCollapseRect = boundingRect
        this.height = 60
      } else {
        const futureYPosition = boundingRect.top - this.preCollapseRect.height
        if (futureYPosition < 0) {
          this.y = 10
        }
        this.height = this.preCollapseRect.height
        this.$nextTick(() => {
          this.preventOverflow()
        })
      }
    },
    initializeInteract() {
      const element = this.$refs.copilotContainer as HTMLElement
      if (!element) {
        return this.$setTimeout(this.initializeInteract, 100)
      }

      interact(element)
        .resizable({
          edges: { left: true, right: true, bottom: true, top: true },
          listeners: {
            start: this.onResizeStart,
            move: this.onResize,
            end: this.onResizeEnd,
          },
          modifiers: [
            interact.modifiers.restrictEdges({
              outer: this.$refs.wrapper,
              endOnly: true,
            }),
            interact.modifiers.restrictSize({
              min: { width: 485, height: 350 },
            }),
          ],
        })
        .draggable({
          allowFrom: ".e-chat-header",
          listeners: {
            start: this.onDragStart,
            move: this.onDrag,
            end: this.onDragEnd,
          },
          modifiers: [
            interact.modifiers.restrictRect({
              restriction: this.$refs.wrapper,
              endOnly: true,
            }),
          ],
        })
      this.isInteractive = true
    },
    destroyInteract() {
      if (!this.isInteractive) {
        return
      }

      const element = this.$refs.copilotContainer as HTMLElement
      if (element) {
        interact(element).unset()

        this.isInteractive = false
      }
    },
    onDragStart() {
      this.isDragging = true
    },
    onDrag(event) {
      this.x += event.dx
      this.y += event.dy
    },
    onDragEnd() {
      this.isDragging = false
    },
    onResizeStart() {
      if (!this.copilotStore.isCollapsed) {
        this.isResizing = true
      }
    },
    onResize(event) {
      if (this.copilotStore.isCollapsed) {
        return
      }
      const { rect, deltaRect } = event

      this.width = rect.width
      this.height = rect.height
      this.y += deltaRect.bottom
      this.x += deltaRect.right
    },
    onResizeEnd() {
      this.isResizing = false
    },
    preventOverflow() {
      const copilotElement = this.$refs.copilotContainer as HTMLElement
      const boundingRect = copilotElement?.getBoundingClientRect()
      if (boundingRect?.top < 0) {
        this.y = 0
      }
    },
  },
})
